import {
    SET_VOUCHER_HISTORY,
    SET_VOUCHER_HISTORY_ACTION_BEFORE,
    SET_VOUCHER_HISTORY_ACTION_FINISH,
    SET_VOUCHER_HISTORY_FILTERS,
    SET_VOUCHER_HISTORY_SORTING, SET_VOUCHER_HISTORY_TOTALS
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_VOUCHER_HISTORY:
            return {
                ...state,
                vouchers: payload.vouchers.item2,
                total: payload.vouchers.item1,
            }
        case SET_VOUCHER_HISTORY_TOTALS:
            return {
                ...state,
                totals: payload.totals,
            }
        case SET_VOUCHER_HISTORY_SORTING:
            return {
               ...state,
                sorting: {
                    ...state.sorting,
                    ...payload.sorting
                }
            }
        case SET_VOUCHER_HISTORY_FILTERS:
            return {
               ...state,
                filters: {
                    ...payload.filters
                }
            }
        case SET_VOUCHER_HISTORY_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            }
        case SET_VOUCHER_HISTORY_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;
    }
}
