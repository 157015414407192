import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { changePopupVisibility } from 'store/actions/dashboard/common.action';

import { POPUP_TYPE } from 'constants/popup.constants';

import {hasBetsAccess, isTerminalProject} from 'utils/access';

import userInfoType from 'types/userInfo.type';
import Dropdown from "components/common/dropdown";

/** Cashier section in header */
const Cashier = ({
    changePopupVisibility,
    userInfo
}) => {
    const { t } = useTranslation();

    const historyMenuItems = [
        {
            key: "betHistory",
            label: t(`common.betHistory`),
            disabled: !hasBetsAccess(userInfo)
        },
        {
            key: "voucherHistory",
            label: t(`common.voucherHistory`),
            disabled: !isTerminalProject(userInfo.projectType)
        },
    ];


    const handleHistoryClick = (value) => {
        switch (value) {
            case "betHistory":
                if(hasBetsAccess(userInfo)){
                    changePopupVisibility({ key: POPUP_TYPE.BET_HISTORY })
                }
                break;
            case "voucherHistory":
                if(isTerminalProject(userInfo.projectType)){
                    changePopupVisibility({ key: POPUP_TYPE.VOUCHER_HISTORY })
                }
                break;
            default:
                return;
        }
    }

    return (
        <div className='rt--cashier-header-list rt--flex rt--flex-col'>
            <div className='rt--cashier-header-list-header rt--flex rt--align-center rt--justify-center'>
                <span className='rt--title rt--font-normal rt--font-regular'>{t('common.cashier')}</span>
            </div>
            <div className='rt--cashier-header-list-content rt--justify-between rt--flex rt--flex-equal'>
                {
                    isTerminalProject(userInfo.projectType)
                        ? (
                            <div
                                className={'rt--cashier-header-list-content-item rt--flex rt--align-center rt--justify-center rt--flex-equal rt--cursor-pointer'}
                            >
                                <Dropdown
                                    element={
                                        <Fragment>
                                    <span
                                        className='rt--title rt--font-normal rt--font-big rt--text-center'>{t('common.history')}</span>
                                            <i className='icon-down rt--font-bigest' />
                                        </Fragment>
                                    }
                                    items={historyMenuItems.filter(i => !i.disabled)}
                                    onClick={handleHistoryClick}
                                />
                            </div>
                        )
                        : (
                            <div
                                className={
                                    'rt--cashier-header-list-content-item rt--flex rt--align-center rt--justify-center rt--cursor-pointer rt--flex-equal' +
                                    (!hasBetsAccess(userInfo) ? " rt--cashier-header-list-content-item-disabled" : "")
                                }
                                onClick={() => hasBetsAccess(userInfo) && changePopupVisibility({key: POPUP_TYPE.BET_HISTORY})}
                            >
                                <span
                                    className='rt--title rt--font-normal rt--font-big rt--text-center'>{t('common.betHistory')}</span>
                            </div>
                        )
                }

                <div
                    className='rt--cashier-header-list-content-item rt--flex rt--align-center rt--justify-center rt--cursor-pointer rt--flex-equal'
                    onClick={() => changePopupVisibility({key: POPUP_TYPE.PERFORMANCE_REPORT})}
                >
                    <span className='rt--title rt--font-normal rt--font-big rt--text-center'>{t('common.report')}</span>
                </div>

            </div>
        </div>
    )
}

/** Cashier propTypes
 * PropTypes
 */
Cashier.propTypes = {
    /** Redux action to open popup */
    changePopupVisibility: PropTypes.func,
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}

const mapDispatchToProps = dispatch => (
    {
        changePopupVisibility: (data) => {
            dispatch(changePopupVisibility(data))
        }
    }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Cashier
);
