import {
    SET_TERMINAL_AUTOSUGGESTION,
    SET_CASHIER_AUTOSUGGESTION
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TERMINAL_AUTOSUGGESTION:
            return {
                ...state,
                terminals: payload.terminals
            }
        case SET_CASHIER_AUTOSUGGESTION:
            return {
                ...state,
                cashiers: payload.cashiers
            }
        default:
            return state;
    }
}
