export const USER_GENDER = {
    MALE: 1,
    FEMALE: 2,
    NONE: 4
}

export const USER_ROLE = {
    CASHIER: "Cashier",
    MANAGER: "BetShopManager"
}

export const USER_TYPE = {
    ADMIN: 1 << 0,
    AGENT: 1 << 1,
    CASHIER: 1 << 2,
    SUPERAGENT: 1 << 3,
    BETSHOP_MANAGER: 1 << 4,
    AGENT_PLAYER: 1 << 5,
    PLAYER: 1 << 6,
    ACCESS_MANAGER: 1 << 7,
    TERMINAL: 1 << 8,
}
