import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { getBetHistoryTotals } from "store/actions/dashboard/betHistory.action";

import useFormat from "hooks/useFormat";

const totalsColumns = [
    {
        key: "betCount",
        title: "common.betCount",
    },
    {
        key: "betAmount",
        title: "common.betAmount",
    },
    {
        key: "winCount",
        title: "common.winCount",
    },
    {
        key: "winAmount",
        title: "common.winAmount",
    },
    {
        key: "paidCount",
        title: "common.paidCount",
    },
    {
        key: "paidAmount",
        title: "common.paidAmount",
    },
    {
        key: "pendingCount",
        title: "common.pendingCount",
    },
    {
        key: "pendingAmount",
        title: "common.pendingAmount",
    },
];

/** Bet history Table Component */
const BetHistoryTotal = ({ 
    getBetHistoryTotals, 
    totals, 
    userInfo,
    type 
}) => {
    const { t } = useTranslation();
    const { formatAmount } = useFormat();

    const currency = userInfo?.currencies?.[0]?.code ?? null;

    const groupedColumns = useMemo(() => {
        const columns = [];
        for (let i = 0; i < totalsColumns.length; i += 2) {
            columns.push(totalsColumns.slice(i, i + 2));
        }
        return columns;
    }, [totalsColumns]);

    useEffect(() => {
        getBetHistoryTotals(type);
    }, [getBetHistoryTotals]);

    const totalsData = useMemo(() => {
        return totals?.[currency?.toLowerCase()] ?? {};
    }, [totals, currency]);

    return (
        <div className="rt--cashier-total">
            <div className="rt--cashier-total-header rt--flex rt--justify-between">
                <span>{t("common.totals")}</span>
                <span>{currency?.toUpperCase()}</span>
            </div>
            <div className="rt--cashier-total-content">
                {groupedColumns.map((group, index) => (
                    <div
                        key={index}
                        className={
                            "rt--cashier-total-content-card rt--flex " +
                            ("rt--cashier-total-content-card-" + (index + 1))
                        }
                    >
                        {group.map((column) => (
                            <div
                                key={column.key}
                                className="rt--flex rt--flex-col rt--justify-between"
                            >
                                <span className="rt--font-smallest">
                                    {t(column.title)}
                                </span>
                                <span className="rt--font-big">
                                    {["betCount", "winCount", "paidCount", "pendingCount"].includes(column.key)
                                        ? formatAmount(totalsData?.[column.key] ?? 0, '')
                                        : formatAmount(totalsData?.[column.key] ?? 0, currency)
                                    }
                                </span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getBetHistoryTotals: type => {
        dispatch(getBetHistoryTotals(type));
    },
});

const mapStateToProps = ( state, props ) => {
    const type = props.type;

    return {
        totals: state.betHistory[type].totals.totals,
        userInfo: state.profile.userInfo,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BetHistoryTotal);
