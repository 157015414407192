import React, { useMemo } from 'react';

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Input, Select } from "antd";

import Export from "components/common/export";

import ApiUrls from "constants/api.constants";

import { getTerminals, setTerminalsFilters, setTerminalsSorting } from "store/actions/dashboard/terminals.action";

import getColumns from "../helpers/getColumns";
import { TERMINAL_STATE } from "constants/terminal.constants";

import useFormat from "hooks/useFormat";

let timer = null;

const TerminalsFilters = ({
    filters, 
    getTerminals, 
    setTerminalsFilters,
    betShopId
}) => {
    const { t } = useTranslation();
    const {formatAmount} = useFormat();

    const mappedFilters = {
        nameOrId: filters.nameOrId,
        status: filters.status
    }

    const columns = useMemo(() => getColumns({ t, formatAmount }).slice(0, -1), [t, formatAmount]);

    const statusOptions = useMemo(() => {
        return [
            {
                title: t('common.all'),
                value: ""
            },
            {
                title: t('common.active'),
                value: TERMINAL_STATE.ACTIVE
            },
            {
                title: t('common.inactive'),
                value: TERMINAL_STATE.INACTIVE
            },
            {
                title: t('common.blocked'),
                value: TERMINAL_STATE.BLOCKED
            },
        ]
    }, [t]);

    const handleInputChange = value => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (!value || value.length > 2) {
                setTerminalsFilters({
                    nameOrId: value && value.length > 2 ? value : ""
                })
                setTimeout(() => {
                    getTerminals(betShopId)
                }, 0)
            }
        }, 300)
    }

    const handleSelectChange = value => {
        setTerminalsFilters({
            ...filters,
            status: value
        })
        setTimeout(() => {
            getTerminals(betShopId)
        }, 0)
    }

    return (
        <div className='rt--filters-header rt--pb-16 rt--mb-30'>
            <div className='rt--flex rt--justify-between rt--align-end'>
                <div className='rt--flex rt--align-center'>
                    <div>
                        <div className='rt--mb-8'>
                            <span className='rt--title rt--font-regular rt--font-normal'>{t("common.terminalNameOrId")}</span>
                        </div>
                        <div className='rt--filters-header-search'>
                            <Input
                                placeholder={t("common.search")}
                                onChange={e => handleInputChange(e.target.value)}
                            />
                            <i className='icon-search rt--font-bigest'></i>
                        </div>
                    </div>
                    <div className='rt--ml-16'>
                        <div className='rt--mb-8'>
                            <span className='rt--title rt--font-regular rt--font-normal'>{t("common.status")}</span>
                        </div>
                        <div className='rt--filters-header-search'>
                            <Select
                                className='rt--select'
                                defaultValue=""
                                suffixIcon={<i className="icon-down rt--font-bigest"></i>}
                                onChange={handleSelectChange}
                                getPopupContainer={trigger => trigger.parentNode}
                                showSearch={false}
                            >
                                {
                                    statusOptions.map(item => (
                                        <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </div>
                </div>
    
                <div className="rt--flex rt--align-center">
                    <Export
                        filters={mappedFilters}
                        title={t("common.terminals")}
                        columns={columns}
                        url={ApiUrls.EXPORT_TERMINALS}
                        tableName={t("common.terminals")}
                    />
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = (state) => ({
    filters: state.terminals.filters,
})

const mapDispatchToProps = (dispatch) => ({
    getTerminals: betShopId => dispatch(getTerminals(betShopId)),
    setTerminalsFilters: (filters) => dispatch(setTerminalsFilters(filters)),
    setTerminalsSorting: (filters) => dispatch(setTerminalsSorting(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TerminalsFilters);
