import React from 'react';
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import {POPUP_SIZE} from "constants/popup.constants";
import VoucherHistory from "components/common/voucherHistory";

const VoucherHistoryPopup = ({ handleCloseModal }) => {
    const { t } = useTranslation();

    return (
        <Modal
            className={'rt--modal rt--modal-full-height'}
            title={(
                <div className='rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-regular'>{t("common.voucherHistory")}</span>
                    <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                </div>
            )}
            visible={true}
            onCancel={handleCloseModal}
            closable={false}
            maskClosable={false}
            getContainer={() => document.getElementById('rt--modal-root')}
            width={POPUP_SIZE.BIGEST}
            centered
            footer={null}
        >
            <VoucherHistory />
        </Modal>
    );
};

export default VoucherHistoryPopup;
