import React from 'react';

import ManagerBetshopEditComponent from 'components/manager/betshopEdit';

import withAuth from 'hocs/withAuth';

const ManagerBetshopEditRoute = () => {
    return (
        <ManagerBetshopEditComponent /> 
    )
}

export default withAuth(ManagerBetshopEditRoute);