/** Auth */

export const AUTHENTICATE_ACTION_BEFORE = "AUTHENTICATE_ACTION_BEFORE";
export const AUTHENTICATE_ACTION_FINISH = "AUTHENTICATE_ACTION_FINISH";
export const AUTHENTICATE_ACTION_SET_EXPIRE = "AUTHENTICATE_ACTION_SET_EXPIRE";
export const SET_PASSWORD_SETTINGS_ACTION_BEFORE = "SET_PASSWORD_SETTINGS_ACTION_BEFORE";
export const SET_PASSWORD_SETTINGS_ACTION_FINISH = "SET_PASSWORD_SETTINGS_ACTION_FINISH";
export const SET_PASSWORD_SETTINGS = "SET_PASSWORD_SETTINGS";
export const SET_SET_PASSWORD_ACTION_BEFORE = "SET_SET_PASSWORD_ACTION_BEFORE";
export const SET_SET_PASSWORD_ACTION_FINISH = "SET_SET_PASSWORD_ACTION_FINISH";


/** Betshops */

export const SET_BETSHOPS_ACTION_BEFORE = "SET_BETSHOPS_ACTION_BEFORE";
export const SET_BETSHOPS_ACTION_FINISH = "SET_BETSHOPS_ACTION_FINISH";
export const SET_BETSHOPS = "SET_BETSHOPS";
export const SET_BETSHOP_ACTION_BEFORE = "SET_BETSHOP_ACTION_BEFORE";
export const SET_BETSHOP_ACTION_FINISH = "SET_BETSHOP_ACTION_FINISH";
export const SET_BETSHOP = "SET_BETSHOP";

/** Bet History */

export const SET_BET_HISTORY_ACTION_BEFORE = "SET_BET_HISTORY_ACTION_BEFORE";
export const SET_BET_HISTORY_ACTION_FINISH = "SET_BET_HISTORY_ACTION_FINISH";
export const SET_BET_HISTORY = "SET_BET_HISTORY";
export const SET_BET_HISTORY_TOTALS_ACTION_BEFORE = "SET_BET_HISTORY_TOTALS_ACTION_BEFORE";
export const SET_BET_HISTORY_TOTALS_ACTION_FINISH = "SET_BET_HISTORY_TOTALS_ACTION_FINISH";
export const SET_BET_HISTORY_TOTALS = "SET_BET_HISTORY_TOTALS";
export const SET_BET_HISTORY_SORTING = "SET_BET_HISTORY_SORTING";
export const SET_BET_HISTORY_FILTERS = "SET_BET_HISTORY_FILTERS";

/** Providers */

export const SET_PROVIDER = "SET_PROVIDER";
export const LAUNCH_PROVIDER = "LAUNCH_PROVIDER";
export const LAUNCH_PROVIDER_BEFORE = "LAUNCH_PROVIDER_BEFORE";
export const LAUNCH_PROVIDER_FINISH = "LAUNCH_PROVIDER_FINISH";
export const RESET_PROVIDERS = "RESET_PROVIDERS";

export const CHANGE_POPUP_VISIBILITY ="CHANGE_POPUP_VISIBILITY"

/** User Info */

export const SET_USER_INFO_ACTION_BEFORE = "SET_USER_INFO_ACTION_BEFORE";
export const SET_USER_INFO_ACTION_FINISH = "SET_USER_INFO_ACTION_FINISH";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_BALANCE = "SET_BALANCE";
export const SET_LIMIT = "SET_LIMIT";
export const SET_USER_AVAILABLE_CURRENCIES_ACTION_BEFORE = "SET_USER_AVAILABLE_CURRENCIES_ACTION_BEFORE";
export const SET_USER_AVAILABLE_CURRENCIES_ACTION_FINISH = "SET_USER_AVAILABLE_CURRENCIES_ACTION_FINISH";
export const SET_USER_AVAILABLE_CURRENCIES = "SET_USER_AVAILABLE_CURRENCIES";

/** Ticket */

export const SET_TICKET_INFO_ACTION_FINISH = "SET_TICKET_INFO_ACTION_FINISH";
export const SET_TICKET_INFO_ACTION_BEFORE = "SET_TICKET_INFO_ACTION_BEFORE";
export const SET_TICKET_INFO = "SET_TICKET_INFO";
export const SET_PAYOUT_ACTION = "SET_PAYOUT_ACTION";
export const SET_PAYOUT_ACTION_BEFORE = "SET_PAYOUT_ACTION_BEFORE";
export const SET_PAYOUT_ACTION_FINISH = "SET_PAYOUT_ACTION_FINISH";
export const RESET_PAYOUT_ACTION = "RESET_PAYOUT_ACTION";

/** Language  */

export const SET_LANGUAGES_BEFORE = "SET_LANGUAGES_BEFORE";
export const SET_LANGUAGES_AFTER = "SET_LANGUAGES_AFTER";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_TRANSLATIONS_LOADED = "SET_TRANSLATIONS_LOADED";

/** Manager Betshops */

export const SET_MANAGER_BETSHOPS_ACTION_BEFORE = "SET_MANAGER_BETSHOPS_ACTION_BEFORE";
export const SET_MANAGER_BETSHOPS_ACTION_FINISH = "SET_MANAGER_BETSHOPS_ACTION_FINISH";
export const SET_MANAGER_BETSHOPS = "SET_MANAGER_BETSHOPS";
export const SET_MANAGER_BETSHOPS_FILTERS = "SET_MANAGER_BETSHOPS_FILTERS";
export const SET_MANAGER_BETSHOPS_SORTING = "SET_MANAGER_BETSHOPS_SORTING";
export const SET_MANAGER_BETSHOPS_SAVE_ACTION_BEFORE = "SET_MANAGER_BETSHOPS_SAVE_ACTION_BEFORE";
export const SET_MANAGER_BETSHOPS_SAVE_ACTION_FINISH = "SET_MANAGER_BETSHOPS_SAVE_ACTION_FINISH";
export const SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_BEFORE = "SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_BEFORE";
export const SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_FINISH = "SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_ACTION_FINISH";
export const SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_ADD = "SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_ADD";
export const SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_TRANSFER = "SET_MANAGER_BETSHOP_AVAILABLE_CASHIERS_FOR_TRANSFER";
export const SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_BEFORE = "SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_BEFORE";
export const SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_FINISH = "SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS_ACTION_FINISH";
export const SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS = "SET_MANAGER_BETSHOP_AVAILABLE_BETSHOPS";

/** Betshop Cashiers */

export const SET_CASHIERS_ACTION_BEFORE = "SET_CASHIERS_ACTION_BEFORE";
export const SET_CASHIERS_ACTION_FINISH = "SET_CASHIERS_ACTION_FINISH";
export const SET_CASHIERS = "SET_CASHIERS";
export const SET_CASHIERS_FILTERS = "SET_CASHIERS_FILTERS";
export const SET_CASHIERS_SORTING = "SET_CASHIERS_SORTING";
export const SET_CASHIERS_SAVE_ACTION_BEFORE = "SET_CASHIERS_SAVE_ACTION_BEFORE";
export const SET_CASHIERS_SAVE_ACTION_FINISH = "SET_CASHIERS_SAVE_ACTION_FINISH";
export const SET_AVAILABLE_CASHIERS_ACTION_BEFORE = "SET_AVAILABLE_CASHIERS_ACTION_BEFORE";
export const SET_AVAILABLE_CASHIERS_ACTION_FINISH = "SET_AVAILABLE_CASHIERS_ACTION_FINISH";
export const SET_AVAILABLE_CASHIERS = "SET_AVAILABLE_CASHIERS";

/** Cashier Sessions */

export const SET_SESSIONS_ACTION_BEFORE = "SET_SESSIONS_ACTION_BEFORE";
export const SET_SESSIONS_ACTION_FINISH = "SET_SESSIONS_ACTION_FINISH";
export const SET_SESSIONS = "SET_SESSIONS";
export const SET_SESSIONS_SORTING = "SET_SESSIONS_SORTING";
export const SET_SESSIONS_FILTERS = "SET_SESSIONS_FILTERS";

/** Handover */

export const SET_HANDOVER_ACTION_BEFORE = "SET_HANDOVER_ACTION_BEFORE";
export const SET_HANDOVER_ACTION_FINISH = "SET_HANDOVER_ACTION_FINISH";
export const SET_HANDOVER_SAVE_ACTION_BEFORE = "SET_HANDOVER_SAVE_ACTION_BEFORE";
export const SET_HANDOVER_SAVE_ACTION_FINISH = "SET_HANDOVER_SAVE_ACTION_FINISH";
export const SET_AVAILABLE_CASHIERS_FOR_CASHIERS = "SET_AVAILABLE_CASHIERS_FOR_CASHIERS";

/** Wallet */

export const SET_WALLET_ACTION_BEFORE = "SET_WALLET_ACTION_BEFORE";
export const SET_WALLET_ACTION_FINISH = "SET_WALLET_ACTION_FINISH";

/** Transactions */

export const SET_TRANSACTIONS_ACTION_BEFORE = "SET_TRANSACTIONS_ACTION_BEFORE";
export const SET_TRANSACTIONS_ACTION_FINISH = "SET_TRANSACTIONS_ACTION_FINISH";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_TRANSACTIONS_SORTING = "SET_TRANSACTIONS_SORTING";
export const SET_TRANSACTIONS_FILTERS = "SET_TRANSACTIONS_FILTERS";
export const RESET_TRANSACTIONS = "RESET_TRANSACTIONS";

/** Player */

export const SET_PLAYER_ACTION_BEFORE = "SET_PLAYER_ACTION_BEFORE";
export const SET_PLAYER_ACTION_FINISH = "SET_PLAYER_ACTION_FINISH";
export const SET_PLAYER_SAVE_ACTION_BEFORE = "SET_PLAYER_SAVE_ACTION_BEFORE";
export const SET_PLAYER_SAVE_ACTION_FINISH = "SET_PLAYER_SAVE_ACTION_FINISH";
export const SET_PLAYER_REGISTRATION_FORM = "SET_PLAYER_REGISTRATION_FORM";
export const SET_PLAYER_AVAILABLE_CURRENCIES = "SET_PLAYER_AVAILABLE_CURRENCIES";
export const SET_PLAYER_INFO = "SET_PLAYER_INFO";
export const SET_CURRENT_PLAYER = "SET_CURRENT_PLAYER";
export const SET_PLAYER_WITHDRAWALS = "SET_PLAYER_WITHDRAWALS";
export const SET_PLAYER_WITHDRAWALS_ACTION_BEFORE = "SET_PLAYER_WITHDRAWALS_ACTION_BEFORE";
export const SET_PLAYER_WITHDRAWALS_ACTION_FINISH = "SET_PLAYER_WITHDRAWALS_ACTION_FINISH";

/** Betshop Performance Report */

export const SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE = "SET_BETSHOP_PERFORMANCE_REPORT_ACTION_BEFORE";
export const SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH = "SET_BETSHOP_PERFORMANCE_REPORT_ACTION_FINISH";
export const SET_BETSHOP_PERFORMANCE_REPORT = "SET_BETSHOP_PERFORMANCE_REPORT";
export const SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_BEFORE = "SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_BEFORE";
export const SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_FINISH = "SET_BETSHOP_PERFORMANCE_TOTALS_ACTION_FINISH";
export const SET_BETSHOP_PERFORMANCE_TOTALS = "SET_BETSHOP_PERFORMANCE_TOTALS";
export const SET_BETSHOP_PERFORMANCE_REPORT_SORTING = "SET_BETSHOP_PERFORMANCE_REPORT_SORTING";
export const SET_BETSHOP_PERFORMANCE_REPORT_FILTERS = "SET_BETSHOP_PERFORMANCE_REPORT_FILTERS";

/** Cashier Performance Report */
export const SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE = "SET_CASHIER_PERFORMANCE_REPORT_ACTION_BEFORE";
export const SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH = "SET_CASHIER_PERFORMANCE_REPORT_ACTION_FINISH";
export const SET_CASHIER_PERFORMANCE_REPORT = "SET_CASHIER_PERFORMANCE_REPORT";
export const SET_CASHIER_PERFORMANCE_REPORT_FILTERS = "SET_CASHIER_PERFORMANCE_REPORT_FILTERS";
export const SET_PENDING_BETS = "SET_PENDING_BETS";

/** Terminals */
export const SET_TERMINALS_ACTION_BEFORE = "SET_TERMINALS_ACTION_BEFORE";
export const SET_TERMINALS_ACTION_FINISH = "SET_TERMINALS_ACTION_FINISH";
export const SET_TERMINALS_FILTERS = "SET_TERMINALS_FILTERS";
export const SET_TERMINALS_SORTING = "SET_TERMINALS_SORTING";
export const SET_TERMINALS = "SET_TERMINALS";

/** Voucher history */
export const SET_VOUCHER_HISTORY_ACTION_BEFORE = "SET_VOUCHER_HISTORY_ACTION_BEFORE";
export const SET_VOUCHER_HISTORY_ACTION_FINISH = "SET_VOUCHER_HISTORY_ACTION_FINISH";
export const SET_VOUCHER_HISTORY_FILTERS = "SET_VOUCHER_HISTORY_FILTERS";
export const SET_VOUCHER_HISTORY_SORTING = "SET_VOUCHER_HISTORY_SORTING";
export const SET_VOUCHER_HISTORY = "SET_VOUCHER_HISTORY";
export const SET_VOUCHER_HISTORY_TOTALS = "SET_VOUCHER_HISTORY_TOTALS";

/** Autosuggestion */
export const SET_TERMINAL_AUTOSUGGESTION = "SET_TERMINAL_AUTOSUGGESTION";
export const SET_CASHIER_AUTOSUGGESTION = "SET_CASHIER_AUTOSUGGESTION";
