import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import Header from './header';
import Cashiers from "components/common/cashiers"
import Terminals from 'components/common/terminals';

import { isTerminalProject } from "utils/access";

const TAB_TYPE = {
    CASHIER: "cashier",
    TERMINAL: "terminal"
}

/** Betshop Manager Betshops Edit Page */
const BetshopManagerBetshopEdit = ({
    userInfo
}) => {

    const searchParams = useParams();

    const { t } = useTranslation();

    const [ activeTab, setActiveTab] = useState(TAB_TYPE.CASHIER);

    const hasTerminal = isTerminalProject(userInfo.projectType)

    return (
        <Fragment>
            <Header/>
            <Tabs 
                className='rt--tabs'
                onChange={setActiveTab}
                activeKey={activeTab}
            >
                <Tabs.TabPane tab={t("common.cashier")} key={TAB_TYPE.CASHIER}>
                    <Cashiers betShopId={searchParams.id} />
                </Tabs.TabPane>
                {
                    hasTerminal && (
                        <Tabs.TabPane tab={t("common.terminal")} key={TAB_TYPE.TERMINAL}>
                            <Terminals betShopId={searchParams.id}/>
                        </Tabs.TabPane>
                    )
                }
                
            </Tabs>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}


export default connect(mapStateToProps, null)(BetshopManagerBetshopEdit);