import React from "react";

import moment from "moment";

import { DATE_TIME_FORMAT } from "constants/common.constants";
import { getTerminalStatusText } from "utils/common";
import { TERMINAL_STATE } from "constants/terminal.constants";
import BlockModal from "components/common/terminals/blockModal";

const getColumns = ({
    t,
    formatAmount,
    betShopId
}) => [
    {
        key: "longId",
        title: t("common.id"),
    },
    {
        key: "name",
        title: t("common.name"),
    },
    {
        key: "lastCashRemovalDate",
        title: t("common.lastCashRemoval"),
        render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
    },
    {
        key: "status",
        title: t("common.status"),
        render: value => (
            <div className='rt--flex rt--align-center rt--status' data-type={value === TERMINAL_STATE.ACTIVE ? "success" : value === TERMINAL_STATE.INACTIVE ? "pending" : "error"}>
                <span className='rt--title rt--font-normal rt--font-regular rt--font-capitalize'>{getTerminalStatusText(value)}</span>
            </div>
        ),
    },
    {
        key: "version",
        title: t("common.version"),
    },
    {
        key: "terminalBalance",
        title: t("common.terminalBalance"),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
    },
    {
        key: "basketBalance",
        title: t("common.basketBalance"),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
    },
    {
        key: 'basketCount',
        title: t("common.billCount"),
    },
    {
        key: 'actions',
        render: (_, record) => record.status === TERMINAL_STATE.INACTIVE ? null : <BlockModal terminal={record} betShopId={betShopId} />
    }
]

export default getColumns;
