import React from "react";
import moment from "moment";
import {DATE_TIME_FORMAT} from "constants/common.constants";
import {getVoucherStatusText} from "utils/common";
import {VOUCHER_STATE} from "constants/voucher.constants";
import ReprintModal from "components/common/voucherHistory/reprintModal";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";

const getColumns = ({ t, formatAmount, isCashier }) => [
    {
        key: "id",
        title: t("common.id"),
        copy: true,
        totalsRender: () => <span className='rt--title rt--font-normal rt--font-regular'>{t("common.totalAmount")}</span>
    },
    {
        key: "createdByName",
        title: t("common.createdBy"),
    },
    {
        key: "createdAt",
        title: t("common.createdAt"),
        render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
        sorting: true
    },
    {
        key: "amount",
        title: t("common.amount"),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>,
        totalsRender: (totals) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(totals.amount, totals.currencyCode)}</span>,
    },
    !isCashier && {
        key: "currencyCode",
        title: t("common.currency"),
    },
    {
        key: "usedByName",
        title: t("common.usedBy"),
        render: (value) => <span className='rt--title rt--font-normal rt--font-regular'>{value || "-"}</span>
    },
    {
        key: "status",
        title: t("common.status"),
        render: value => (
            <div
                className='rt--flex rt--align-center rt--status'
                data-type={value === VOUCHER_STATE.ACTIVE ? "success" : [VOUCHER_STATE.PAID_OUT, VOUCHER_STATE.USED].includes(value) ? "pending" : "error"}
            >
                <span className='rt--title rt--font-normal rt--font-regular rt--font-capitalize'>{getVoucherStatusText(value)}</span>
            </div>
        ),
    },
    {
        key: "paidOutByName",
        title: t("common.paidOutBy"),
        render: (value) => <span className='rt--title rt--font-normal rt--font-regular'>{value || "-"}</span>
    },
    {
        key: "paidOutDate",
        title: t("common.payOutDate"),
        render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
    },
    {
        key: "expirationDate",
        title: t("common.expirationDate"),
        render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
    },
    isCashier && {
        key: 'actions',
        render: (_, record) => record.status === VOUCHER_STATE.ACTIVE && getUser()?.role === USER_ROLE.CASHIER && <ReprintModal voucher={record} />
    }
]

export default getColumns;
