import React from 'react';

import { useTranslation } from 'react-i18next';

/** Betshop Manager Bet History Page Header Component */

const BetHistoryHeader = () => {

    const { t } = useTranslation();    

    return (
        <div className='rt--filters-header rt--flex rt--justify-between rt--align-center rt--mt-32 rt--pb-24 rt--mb-30'>
            <div className='rt--flex rt--align-center rt--filters-header-title'>
                <span className='rt--title rt--font-big rt--font-regular rt--pl-4'>{t("common.betHistory")}</span>
            </div>
        </div>
    )
}

export default BetHistoryHeader;
