import React, {useEffect} from 'react';
import RowTotals from "components/common/rowTotals";
import {connect} from "react-redux";
import {getVoucherHistoryTotals} from "store/actions/dashboard/voucherHisrory.action";

const VoucherHistoryTotals = ({ name, columns = [], totals = [], gerVoucherHistoryTotals }) => {
    useEffect(() => {
        gerVoucherHistoryTotals()
    }, []);

    console.log({totals})

    return (
        <RowTotals name="voucherHistory" columns={columns} totals={totals[0]} />
    );
};

const mapStateToProps = (state) => ({
    totals: state.voucherHistory.totals
});

const mapDispatchToProps = (dispatch) => ({
    gerVoucherHistoryTotals: () => dispatch(getVoucherHistoryTotals())
})

export default connect(mapStateToProps, mapDispatchToProps)(VoucherHistoryTotals);
