import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Filters from "components/common/filters";
import Export from "components/common/export";

import { setCashierPerformanceReportFilters, getCurrentCashierPerformanceReport, getPendingBets } from "store/actions/dashboard/cashierPerformanceReport.action";

import { DATE_PICKER_RANGES } from 'constants/common.constants';
import ApiUrls from "constants/api.constants";

import { TIME_PICKER_MODES } from 'components/common/dateRangePicker/constants';
import columns from "components/common/performanceReport/performanceReport/columns";

/** Performance Report Filters Component */
const PerformanceReportFilters = ({
    setCashierPerformanceReportFilters,
    getCurrentCashierPerformanceReport,
    filters,
	getPendingBets,
	pendingBets,
}) => {

    const { t } = useTranslation();

    /** Flag to hide/show filters */
    const [opened, setOpened] = useState(false);

    const mappedFilters = {
        from: filters.from,
        to: filters.to,
        period: filters.period
    }

	useEffect(() => {
		getPendingBets();
	}, [])

    return (
        <div className='rt--filters-header rt--mt-8 rt--pb-24'>

            <div className='rt--flex rt--justify-between rt--align-center'>
                <div className='rt--flex rt--align-center rt--filters-header-title'>
							{pendingBets.totalBetsAmount !== null && (
								<div className='rt--filters-header-bendingBets rt--mr-16'>
									<span className='rt--filters-header-bendingBets-title'>{t("common.pendingBetCount")}</span>
									<span className='rt--filters-header-bendingBets-value'>{pendingBets.totalBetsCount}</span>
								</div>
							)}
							{pendingBets.totalBetsCount !== null && (
								<div className='rt--filters-header-bendingBets'>
									<span className='rt--filters-header-bendingBets-title'>{t("common.pendingBetAmount")}</span>
									<span className='rt--filters-header-bendingBets-value'>{pendingBets.totalBetsAmount}</span>
								</div>
							)}
                </div>

                <div className='rt--flex'>
                    <Export
                        filters={{
                            ...mappedFilters,
                            filterType: 1
                        }}
                        columns={columns}
                        url={ApiUrls.EXPORT_CASHIER_PERFORMANCE_REPORT}
                        tableName={t("common.report")}
                    />
                    <button
                        className='rt--button rt--button-secondary rt--ml-16'
                        onClick={() => setOpened(!opened)}
                    >
                        <span className='rt--flex rt--justify-center rt--align-center'>
                            <i className='icon-filter rt--font-bigest'></i>
                            <span className='rt--title rt--font-medium rt--font-normal'>{t("common.filters")}</span>
                        </span>
                    </button>

                </div>
            </div>
            <Filters
                loadFn={getCurrentCashierPerformanceReport}
                setFiltersFn={setCashierPerformanceReportFilters}
                filters={mappedFilters}
                controls={[
                    {
                        title: t('common.timePeriod'),
                        name: "period",
                        type: "SELECT",
                        small: true,
                        items: [
                            { value: DATE_PICKER_RANGES.TODAY, title: t('common.today') },
                            { value: DATE_PICKER_RANGES.YESTERDAY, title: t('common.yesterday') },
                            { value: DATE_PICKER_RANGES.THIS_MONTH, title: t('common.thisMonth') },
                            { value: DATE_PICKER_RANGES.LAST_MONTH, title: t('common.lastMonth') },
                            { value: DATE_PICKER_RANGES.LAST_3_MONTH, title: t('common.3Months') },
                            { value: DATE_PICKER_RANGES.YTD, title: t('common.ytd') },
                            { value: DATE_PICKER_RANGES.LAST_YEAR, title: t('common.lastYear') }
                        ]
                    },
                    {
                        title: t('common.dateRange'),
                        name: "date",
                        type: "RANGEPICKER",
                        mode: TIME_PICKER_MODES.ONLY_HOURS
                    }
                ]}
                useDateWithTime={true}
                visible={opened}
            />
        </div>

    )
}

/** PerformanceReportFilters propTypes
    * PropTypes
*/
PerformanceReportFilters.propTypes = {
    /** Redux action to get current cashier performance report */
    getCurrentCashierPerformanceReport: PropTypes.func,
    /** Redux action to get pending bets */
    getPendingBets: PropTypes.func,
    /** Redux action to set cashier performance report filters */
    setCashierPerformanceReportFilters: PropTypes.func,
    /** Redux state property, cashier performance report filters */
    filters: PropTypes.object,
    /** Redux state property, pending bets */
    pendingBets: PropTypes.object,
}

const mapDispatchToProps = dispatch => (
    {
        getCurrentCashierPerformanceReport: () => {
            dispatch(getCurrentCashierPerformanceReport());
        },
        getPendingBets: () => {
            dispatch(getPendingBets());
        },

        setCashierPerformanceReportFilters: filters => {
            dispatch(setCashierPerformanceReportFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.cashierPerformanceReport.filters,
        pendingBets: state.cashierPerformanceReport.pendingBets,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceReportFilters);
