import React from 'react';

const RowTotals = ({ name, columns = [], totals }) => {
    return (
        <div data-name={name} className="rt--row-total">
            {columns.map(column => (
                <div key={column.key} className="rt--row-total-col" data-type={column.key}>{totals && column.totalsRender?.(totals)}</div>
            ))}
        </div>
    );
};

export default RowTotals;
