import React from "react";

import moment from "moment";

import { DATE_TIME_FORMAT, USER_STATE } from "constants/common.constants";

const getColumns = ({
    t, 
    formatAmount
}) => [
    {
        key: "firstName",
        title: t("common.cashier"),
        render: ( value, record) => (
            <div className='rt--flex rt--align-center'>
                <div
                    className='rt--table-marker rt--mr-8'
                    data-type={ record.isOnline ? "success" : "inactive" }
                />
                <span title={value + " " + record.lastName} className='rt--cashier-name rt--title rt--font-normal rt--font-regular'>{value + " " + record.lastName}</span>
            </div>
        ),
        sorting: true
    },
    {
        key: "userName",
        title: t("common.username"),
        sorting: true
    },
    {
        key: "longId",
        title: t("common.id"),
        sorting: true,
        copy: true
    },
    {
        key: "state",
        title: "Status",
        render: value => (
            <div className='rt--flex rt--align-center'>
                <div
                    className='rt--table-marker rt--mr-8'
                    data-type={
                        value === USER_STATE.ACTIVE ? "success":
                            value === USER_STATE.IN_PROGRESS ? "pending" : "error"
                    }
                />
                <span className='rt--title rt--font-normal rt--font-regular'>{
                    value === USER_STATE.ACTIVE ? t("common.active") :
                        value === USER_STATE.IN_PROGRESS ? t("common.inProgress") : t("common.blocked")
                }</span>
            </div>
        ),
        sorting: true
    },
    {
        key: "lastLogin",
        title: t("common.lastLogin"),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
        sorting: true
    },
    {
        key: "balance",
        title: t("common.betshopSpecificBalance"),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, record.currencyCode)}</span>
    }
]

export default getColumns;
