import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import BetHistoryTable from "./betHistoryTable";
import Filters from "./filters";

import { getColumns } from "./helpers/getColumns";

import useFormat from "hooks/useFormat";

import { BETSHOP_BET_TYPE } from "constants/common.constants";

/** Bet History Component */
const BetHistory = ({ 
    userInfo,
    type 
}) => {
    const { t } = useTranslation();
    const { formatAmount } = useFormat();

    const canPlaceAnonymousBets = BETSHOP_BET_TYPE.ANONYMOUS === userInfo.bettingType;
    const columns = getColumns({ canPlaceAnonymousBets, formatAmount, t, type });

    return (
        <div className="rt--flex rt--flex-col rt--height-100">
            <Filters
                columns={columns}
                canPlaceAnonymousBets={canPlaceAnonymousBets}
                type={type}
            />
            <BetHistoryTable columns={columns} userInfo={userInfo} type={type}/>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userInfo: state.profile.userInfo,
    };
};

export default connect(mapStateToProps)(BetHistory);
