import React from 'react';

import { useNavigate } from 'react-router-dom';

import PATHS from 'constants/path.constants';

/** Betshop Manager Betshop Edit Page Header Component */

const BetshopEditHeader = () => {

    const navigate = useNavigate();    

    const betShopName = (new URLSearchParams(location.search)).get("name")

    return (
        <div className='rt--filters-header rt--flex rt--justify-between rt--align-center rt--mt-32 rt--pb-24 rt--mb-30'>
            <div className='rt--flex rt--align-center rt--filters-header-title'>
                <i
                    className='icon-left rt--font-bigest rt--cursor-pointer'
                    onClick={() => navigate(PATHS.MANAGER)}
                />
                <span className='rt--title rt--font-big rt--font-regular rt--pl-4'>{betShopName}</span>
            </div>
        </div>
    )
}

export default BetshopEditHeader;
