import React from 'react';

// import VoucherHistoryComponent from 'components/common/voucherHistory';

import withAuth from 'hocs/withAuth';
import VoucherHistory from "components/common/voucherHistory";

const ManagerVoucherHistoryRoute = () => {
    return (
        <VoucherHistory />
    )
}

export default withAuth(ManagerVoucherHistoryRoute);
