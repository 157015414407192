import React, {useState} from 'react';
import {Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
import VoucherTicket from "components/common/tickets/voucher";
import {printElement} from "utils/common";
import {VOUCHER_STATE} from "constants/voucher.constants";
import {getUser} from "utils/auth";
import {USER_ROLE} from "constants/user.constants";

const ReprintModal = ({ voucher }) => {
    const [opened, setOpened] = useState(false);

    const { t } = useTranslation();

    const handleOpenModal = () => {
        setOpened(true);
    }

    const handleCloseModal = () => {
        setOpened(false);
    }

    const handleReprint = () => {
        handleCloseModal();

        printElement(voucher.id);
    }

    return (
        <>
            <Button type="ghost" onClick={handleOpenModal}>
                <i className="icon-printer rt--font-bigest"/>
            </Button>

            <Modal
                className='rt--modal rt--modal-register-player-modal'
                title={(
                    <div className='rt--flex rt--justify-between rt--align-center'>
                        <span className='rt--title rt--font-normal rt--font-regular'>{t("common.reprintVoucher")}</span>
                        <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                    </div>
                )}
                visible={opened}
                onCancel={handleCloseModal}
                closable={false}
                maskClosable={false}
                getContainer={() => document.getElementById('rt--modal-root')}
                cancelButtonProps={{ className: 'rt--button-secondary' }}
                cancelText={t('common.cancel')}
                okText={t('common.reprint')}
                onOk={handleReprint}
                centered
            >
                <span className='rt--title rt--font-normal rt--font-regular'>
                    {t("common.reprintVoucherMessage")}
                </span>
            </Modal>

            {(
                voucher &&
                voucher.status === VOUCHER_STATE.ACTIVE &&
                getUser()?.role === USER_ROLE.CASHIER &&
                <div style={{ display: 'none' }}><VoucherTicket voucher={voucher} reprint /></div>
            )}
        </>
    );
};

export default ReprintModal;
