import axios from "axios";
import ApiUrls from "constants/api.constants.js";
import Methods from "constants/httpMethods.constants.js";

export const changeTerminalState = (data) => {
    return axios({
        url: ApiUrls.CHANGE_TERMINAL_STATE,
        method: Methods.POST,
        data: data,
    })
}
