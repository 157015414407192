import React from 'react';
import TerminalsFilters from "./filters";
import TerminalsTable from "./table";

const Terminals = ({
    betShopId
}) => {
    return (
        <div className="rt--flex rt--flex-col" style={{ height: "100%" }}>
            <TerminalsFilters betShopId={betShopId} />

            <TerminalsTable betShopId={betShopId} />
        </div>
    )
};

export default Terminals;
