import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from "moment";

import Table from "components/common/table";
import Pagination from "components/common/pagination";

import {
    getTransactions,
    setTransactionsFilters,
    setTransactionsSorting,
} from 'store/actions/dashboard/transactions.action';

import { WALLET_OWNER_TYPE, WALLET_TYPE } from 'constants/common.constants';
import { USER_ROLE } from 'constants/user.constants';

import { getUser } from 'utils/auth';

import { makeTransactionText } from "../heplers/makeTransactionText";

import sortingType from 'types/sorting.type';
import transactionType from 'types/transaction.type';

import noDataImg from "assets/images/noContent.png";


/** Transactions Table Component */

const Transactions = ({
    type,
    getTransactions,
    setTransactionsSorting,
    setTransactionsFilters,
    filters,
    isLoading,
    transactions,
    sorting,
    total,
    columns
}) => {

    const { t } = useTranslation();

    /** Function for transactions mapping
       * @function
       * @param {array} transactions
       * @returns {array}
       * @memberOf Transactions
    */
    const groupTransactionByUserName = transactions => {
        const result = {};
        transactions.forEach(transaction => {
            if (!result[transaction.ownerName]) {
                result[transaction.ownerName] = {}
            }
            result[transaction.ownerName]['ownerName'] = transaction.ownerName;
            result[transaction.ownerName]['ownerType'] = transaction.ownerType;
            result[transaction.ownerName][transaction.walletType] = {
                before: transaction.before,
                after: transaction.after
            }
        })
        return Object.keys(result).map(key => ({ ownerName: key, ...(result[key]) }));
    }

    /** Function to render row content
		 * @function
         * @param {object} record - the transaction
         * @returns {JSX}
		 * @memberOf Transactions
    */
    const renderRowContent = record => {
        return (
            <div className='rt--table-transactions-inner rt--pt-8 rt--pb-8'>
                {
                    record.transactions.map((transaction, index) => (
                        <div
                            className={"rt--table-transactions-inner-item rt--mb-8" + (index > 0 && !record.transactions[0].transactionItems ? " rt--table-transactions-inner-item-first-hidden" : "")}
                            key={transaction.transactionId}
                        >
                            {
                                index > 0 && !record.transactions[0].transactionItems && <i className="icon-pointing_arrow rt--font-bigest rt--mr-4" />
                            }
                            <div className="rt--table-transactions-inner-item-header rt--flex rt--align-center rt--mb-6">

                                {
                                    !transaction.transactionItems && (
                                        <Fragment>
                                            {
                                                index !== 0 && <i className="icon-pointing_arrow rt--font-bigest rt--mr-4" />
                                            }


                                            <div className="rt--table-transactions-inner-item-header-marker rt--mr-4" />
                                        </Fragment>
                                    )
                                }
                                {
                                    transaction.transactionItems ? (
                                        <Fragment>
                                            <b className="rt--title rt--font-small rt--font-regular rt--table-transactions-inner-item-header-title">{makeTransactionText(transaction.transactionType, t)}</b>
                                            <span className="rt--title rt--font-small rt--font-regular rt--pl-4 rt--pr-4">{`${t("common.on")} ${moment.utc(transaction.transactionTime).local().format("HH:mm  ll")}`}</span>
                                        </Fragment>
                                    ) : (
                                        <span className="rt--title rt--font-small rt--font-regular">{`${makeTransactionText(transaction.transactionType, t)} ${t("common.on")} ${moment.utc(transaction.transactionTime).local().format("HH:mm  ll")}`}</span>
                                    )
                                }
                            </div>
                            <div className="rt--table-transactions-inner-item-content">
                                {
                                    transaction.transactionItems && transaction.transactionItems.length > 1 && (
                                        <div className="rt--table-transactions-inner-item-content-border" />
                                    )
                                }

                                {
                                    transaction.transactionItems ? (
                                        <div className="rt--flex rt--align-center rt--table-transactions-inner-item-content-inner">
                                            <div className="rt--table-transactions-inner-item-content-inner-user">
                                                {
                                                    groupTransactionByUserName(transaction.transactionItems).map(item => (
                                                        <div className="rt--table-transactions-inner-item-content-inner-user-item rt--mb-10 rt--pr-8" key={item.ownerName}>
                                                            <div className="rt--flex rt--align-center">
                                                                <div className='rt--table-transactions-inner-item-content-inner-user-item-icon rt--flex rt--align-center rt--justify-center rt--mr-4'>
                                                                    <i className={
                                                                        (
                                                                            item.ownerType === WALLET_OWNER_TYPE.BETSHOP ? "icon-betshops" :
                                                                                item.ownerType === WALLET_OWNER_TYPE.CASHIER ? "icon-cashier" : 
                                                                                    item.ownerType === WALLET_OWNER_TYPE.VOUCHER ? "icon-ticket" : "icon-agent"
                                                                        ) + " rt--font-small"
                                                                    } />
                                                                </div>
                                                                <span 
                                                                    className="rt--title rt--font-small rt--font-regular" 
                                                                    title={ item.ownerType === WALLET_OWNER_TYPE.VOUCHER  ? t("common.voucher") : item.ownerName }
                                                                >
                                                                    { item.ownerType === WALLET_OWNER_TYPE.VOUCHER  ? t("common.voucher") : item.ownerName }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className="rt--table-transactions-inner-item-content-inner-wallet">
                                                {
                                                    groupTransactionByUserName(transaction.transactionItems).map(item => (
                                                        <div className="rt--table-transactions-inner-item-content-inner-wallet-item rt--mb-10" key={item.ownerName}>
                                                            <div className="rt--flex rt--align-center">
                                                                {
                                                                    item[WALLET_TYPE.BALANCE] && (
                                                                        <div className="rt--flex rt--align-center rt--pl-8 rt--pr-8">
                                                                            <div className="rt--flex rt--align-center">
                                                                                <span className="rt--title rt--font-small rt--font-bold rt--pr-4 rt--table-transactions-inner-item-content-inner-wallet-item-title">{t("common.balance")}:</span>
                                                                            </div>
                                                                            <div className="rt--flex rt--align-center">
                                                                                <span className="rt--title rt--font-small rt--font-regular rt--pr-4">{item[WALLET_TYPE.BALANCE]['before']}</span>
                                                                                <i className={(item[WALLET_TYPE.BALANCE]['before'] < item[WALLET_TYPE.BALANCE]['after'] ? "icon-arrow-up-right" : "icon-arrow-down-right") + " rt--font-normal"} />
                                                                                <span className="rt--title rt--font-small rt--font-regular rt--pl-4">{item[WALLET_TYPE.BALANCE]['after']}</span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    item[WALLET_TYPE.LIMIT] && (
                                                                        <div className="rt--flex rt--align-center rt--pl-8 rt--pr-8">
                                                                            <div>
                                                                                <span className="rt--title rt--font-small rt--font-bold rt--pr-4 rt--table-transactions-inner-item-content-inner-wallet-item-title">{t("common.limit")}:</span>
                                                                            </div>
                                                                            <div className="rt--flex rt--align-center">
                                                                                <span className="rt--title rt--font-small rt--font-regular rt--pr-4">{item[WALLET_TYPE.LIMIT]['before']}</span>
                                                                                <i className={(item[WALLET_TYPE.LIMIT]['before'] < item[WALLET_TYPE.LIMIT]['after'] ? "icon-arrow-up-right" : "icon-arrow-down-right") + " rt--font-normal"} />
                                                                                <span className="rt--title rt--font-small rt--font-regular rt--pl-4">{item[WALLET_TYPE.LIMIT]['after']}</span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ) : null
                                }

                            </div>
                            {
                                (transaction.transactionItems && record.transactions.filter(tr => tr.transactionItems).length > 1 && index !== record.transactions.length - 1) && (
                                    <div className="rt--table-transactions-inner-item-separator rt--mt-8" />
                                )
                            }

                        </div>
                    ))
                }
            </div>
        )
    }

    return (
        <Fragment>
            <Table
                name={type === WALLET_OWNER_TYPE.CASHIER ? getUser()?.role === USER_ROLE.MANAGER ? "cashierTransactions" : "cashierManagerTransactions" : "betshopTransactions"}
                loadFn={() => getTransactions(type)}
                defaultSortingColumn="TransactionTime"
                setSortingFn={setTransactionsSorting}
                columns={columns}
                data={transactions}
                isLoading={isLoading}
                sorting={sorting}
                emptyImg={noDataImg}
                emptyText={t("common.noTransactionFound")}
                actions={[]}
                enableReload={true}
                setFiltersFn={f => setTransactionsFilters(f, type)}
                filters={filters[type]}
                uniqueKey="groupId"
                expandable={true}
                renderExpandContent={renderRowContent}
            />
            <Pagination
                total={total}
                onChange={value => setTransactionsSorting({
                    ...sorting,
                    ...value
                })}
                page={sorting.page}
                limit={sorting.limit}
                loadFn={() => getTransactions(type)}
            />
        </Fragment>

    )
}

/** Transactions propTypes
    * PropTypes
*/
Transactions.propTypes = {
    /** Transactions owner type */
    type: PropTypes.oneOf(Object.values(WALLET_OWNER_TYPE)),
    /** Redux action to get transactions */
    getTransactions: PropTypes.func,
    /** Redux action to set transactions sorting */
    setTransactionsSorting: PropTypes.func,
    /** Redux action to set transactions filters */
    setTransactionsFilters: PropTypes.func,
    /** Redux state property, is true when loading transactions */
    isLoading: PropTypes.bool,
    /** Redux state property, transactions sorting */
    sorting: sortingType,
    /** Redux state property, transactions filters */
    filters: PropTypes.object,
    /** Redux state property, transactions items count */
    total: PropTypes.number,
    /** Redux state property, the array of transactions */
    transactions: PropTypes.arrayOf(transactionType),
    /** Table columns */
    columns: PropTypes.arrayOf(PropTypes.object)
}

const mapDispatchToProps = dispatch => (
    {
        getTransactions: type => {
            dispatch(getTransactions(type));
        },

        setTransactionsSorting: sorting => {
            dispatch(setTransactionsSorting(sorting));
        },

        setTransactionsFilters: (filters, type) => {
            dispatch(setTransactionsFilters(filters, type));
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.transactions.isLoading,
        sorting: state.transactions.sorting,
        transactions: state.transactions.transactions,
        filters: state.transactions.filters,
        total: state.transactions.total
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Transactions
);
