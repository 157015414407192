import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";

import { PRODUCT_TYPE } from "constants/common.constants";
import { BET_HISTORY_TAB_TYPE, BET_STATE } from "constants/bets.constants";
import { USER_ROLE } from "constants/user.constants";
import {
    SET_BET_HISTORY_ACTION_BEFORE,
    SET_BET_HISTORY_ACTION_FINISH,
    SET_BET_HISTORY,
    SET_BET_HISTORY_SORTING,
    SET_BET_HISTORY_FILTERS,
    SET_BET_HISTORY_TOTALS_ACTION_BEFORE,
    SET_BET_HISTORY_TOTALS_ACTION_FINISH,
    SET_BET_HISTORY_TOTALS
} from "store/actionTypes";

import { getUser } from "utils/auth";

const setBetHistoryActionBefore = () => ({
    type: SET_BET_HISTORY_ACTION_BEFORE,
});

const setBetHistoryActionFinished = () => ({
    type: SET_BET_HISTORY_ACTION_FINISH,
});


const setBetHistory = ( bets, type ) => ({
    type: SET_BET_HISTORY,
    payload: { bets, type },
});

const setBetHistoryTotalsActionBefore = type => ({
    type: SET_BET_HISTORY_TOTALS_ACTION_BEFORE,
    payload: { type }
});

const setBetHistoryTotalsActionFinished = type => ({
    type: SET_BET_HISTORY_TOTALS_ACTION_FINISH,
    payload: { type }
});

const setBetHistoryTotals = (totals, type) => ({
    type: SET_BET_HISTORY_TOTALS,
    payload: { totals, type },
})

export const setBetHistorySorting = (sorting, type) => ({
    type: SET_BET_HISTORY_SORTING,
    payload: { sorting, type },
});

export const setBetHistoryFilters = (filters, type) => ({
    type: SET_BET_HISTORY_FILTERS,
    payload: { filters, type },
});

export const getBetHistory = type => {
    return (dispatch, getState) => {
        dispatch(setBetHistoryActionBefore());

        const betHistory = getState().betHistory[type]

        const params = {
            ...betHistory.sorting,
            ...betHistory.filters,
            productType : PRODUCT_TYPE.RETAIL,
            filterType: 1
        }

        if (params.status === BET_STATE.PAID_OUT) {
            params.IsPaidout = true;
            params.status = "";
        }

        if (params.status === BET_STATE.CASHED_OUT) {
            params.IsCashedout = true;
            params.status = "";
        }

        return axios({
            url: type === BET_HISTORY_TAB_TYPE.CASHIER ? ApiUrls.GET_CASHIER_BET_HISTORY : ApiUrls.GET_TERMINAL_BET_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                dispatch(setBetHistory(bets, type));
                dispatch(setBetHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setBetHistoryActionFinished());
            });
    }
}

export const getBetHistoryTotals = type => {
    return (dispatch, getState) => {
        dispatch(setBetHistoryTotalsActionBefore(type));

        const betHistory = getState().betHistory[type]

        const userRole = getUser()?.role;
        const currencyCode = getState().profile.userInfo?.currencies?.[0].code.toLowerCase() ?? null;

        const params = {
            ...betHistory.sorting,
            ...betHistory.filters,
            productType : PRODUCT_TYPE.RETAIL,
            filterType: 1
        }

        if (params.status === BET_STATE.PAID_OUT) {
            params.IsPaidout = true;
            params.status = "";
        }

        if (params.status === BET_STATE.CASHED_OUT) {
            params.IsCashedout = true;
            params.status = "";
        }

        return axios({
            url: type === BET_HISTORY_TAB_TYPE.CASHIER ? ApiUrls.GET_CASHIER_BETS_TOTALS : ApiUrls.GET_TERMINAL_BETS_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                if (userRole === USER_ROLE.CASHIER && currencyCode) {
                    dispatch(setBetHistoryTotals({[currencyCode]: bets[currencyCode]} , type ));
                } else {
                    dispatch(setBetHistoryTotals(bets, type));
                }

                dispatch(setBetHistoryTotalsActionFinished(type));
            })
            .catch((ex) => {
                dispatch(setBetHistoryTotalsActionFinished(type));
            });
    }
}
