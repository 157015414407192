import React from 'react';

import BetHistoryComponent from 'components/manager/betHistory';

import withAuth from 'hocs/withAuth';

const ManagerBetHistoryRoute = () => {
    return (
        <BetHistoryComponent /> 
    )
}

export default withAuth(ManagerBetHistoryRoute);