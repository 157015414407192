import React, { useState } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import BetHistoryComponent from './betHistory'; 

import { isTerminalProject } from "utils/access";

import { BET_HISTORY_TAB_TYPE } from 'constants/bets.constants';


/** Bet History Component */
const BetHistory = ({
    userInfo
}) => {

    const { t } = useTranslation();

    const [ activeTab, setActiveTab] = useState(BET_HISTORY_TAB_TYPE.CASHIER);

    const hasTerminal = isTerminalProject(userInfo.projectType)

    return (
        <Tabs 
            className='rt--tabs'
            onChange={setActiveTab}
            activeKey={activeTab}
        >
            <Tabs.TabPane tab={t("common.cashier")} key={BET_HISTORY_TAB_TYPE.CASHIER}>
                <BetHistoryComponent type={BET_HISTORY_TAB_TYPE.CASHIER} />
            </Tabs.TabPane>
            {
                hasTerminal && (
                    <Tabs.TabPane tab={t("common.terminal")} key={BET_HISTORY_TAB_TYPE.TERMINAL}>
                        <BetHistoryComponent type={BET_HISTORY_TAB_TYPE.TERMINAL}/>
                    </Tabs.TabPane>
                )
            }
            
        </Tabs>
    )
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}


export default connect(mapStateToProps, null)(BetHistory);