import Methods from "constants/httpMethods.constants";
import PATHS from "constants/api.constants";
import axios from "axios";
import {
    SET_TERMINALS,
    SET_TERMINALS_ACTION_BEFORE,
    SET_TERMINALS_ACTION_FINISH,
    SET_TERMINALS_FILTERS, SET_TERMINALS_SORTING
} from "store/actionTypes";

export const setTerminals = (terminals) => ({
    type: SET_TERMINALS,
    payload: { terminals }
});

export const setTerminalsActionBefore = () => ({
    type: SET_TERMINALS_ACTION_BEFORE,
})

export const setTerminalsActionFinish = () => ({
    type: SET_TERMINALS_ACTION_FINISH,
})

export const setTerminalsFilters = filters => {
    const { from, to, ...restFilters } = filters;

    return ({
        type: SET_TERMINALS_FILTERS,
        payload: {filters: restFilters},
    });
};

export const setTerminalsSorting = sorting => ({
    type: SET_TERMINALS_SORTING,
    payload: { sorting },
});


export const getTerminals = betShopId => {
    return (dispatch, getState) => {
        const filters = getState().terminals.filters;
        const sorting = getState().terminals.sorting;

        const params = {
            ...filters,
            ...sorting
        }

        if(betShopId){
            params.betShopId = betShopId;
        }

        dispatch(setTerminalsActionBefore());

        return axios({
            url: PATHS.GET_TERMINALS,
            method: Methods.GET,
            params: params,
        })
       .then(response => {
            dispatch(setTerminals(response.data.value));
        })
       .catch(error => {
            console.error('Error fetching terminals:', error);
        })
       .finally(() => {
           dispatch(setTerminalsActionFinish());
       });
    };
}