import React, { Fragment } from 'react';

import Header from './header';
import BetHistory from 'components/common/betHistory';

/** Betshop Manager Bet History Page */
const BetHistoryComponeent = () => {
    return (
        <Fragment>
            <Header/>
            <BetHistory />
        </Fragment>
    )
}


export default BetHistoryComponeent;