import axios from "axios";

import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";
import { TRANSACTION_TYPE, WALLET_OWNER_TYPE } from 'constants/common.constants';
import { USER_ROLE } from 'constants/user.constants';

import { getUser } from 'utils/auth';

import {
    SET_TRANSACTIONS_ACTION_BEFORE,
    SET_TRANSACTIONS_ACTION_FINISH,
    SET_TRANSACTIONS,
    SET_TRANSACTIONS_SORTING,
    SET_TRANSACTIONS_FILTERS,
    RESET_TRANSACTIONS
} from "store/actionTypes";

const setTransactionsActionBefore = () => ({
    type: SET_TRANSACTIONS_ACTION_BEFORE,
});

const setTransactionsActionFinished = () => ({
    type: SET_TRANSACTIONS_ACTION_FINISH,
});


const setTransactions = transactions => ({
    type: SET_TRANSACTIONS,
    payload: { transactions },
});

export const setTransactionsSorting = sorting => ({
    type: SET_TRANSACTIONS_SORTING,
    payload: {sorting},
});

export const setTransactionsFilters = (filters, type) => ({
    type: SET_TRANSACTIONS_FILTERS,
    payload: { filters, type },
});

export const resetTransactions = type => ({
    type: RESET_TRANSACTIONS,
    payload: { type }
});

export const getTransactions = type => {
    return (dispatch, getState) => {

        const participants = [];
        const filters = getState().transactions.filters[type];

        if(getUser()?.role === USER_ROLE.MANAGER){
            if(filters.cashier){
                participants.push({
                    ownerType: WALLET_OWNER_TYPE.CASHIER,
                    userNameOrId: filters.cashier
                })
            }

            if(getState().transactions.filters.betShopId){
                participants.push({
                    ownerType: WALLET_OWNER_TYPE.BETSHOP,
                    userNameOrId: filters.betShopId
                })
            }
        } else {
            // /** Cashier */
            // participants.push({
            //     ownerType: WALLET_OWNER_TYPE.CASHIER,
            //     userNameOrId: getState().profile.userInfo.cashier.userName
            // });

            // /** Betshop */
            // participants.push({
            //     ownerType: WALLET_OWNER_TYPE.BETSHOP,
            //     userNameOrId: getState().profile.userInfo.betShopId
            // })
        }

        if(type === WALLET_OWNER_TYPE.CASHIER){
            if(filters.player){

                participants.push({
                    ownerType: WALLET_OWNER_TYPE.PLAYER,
                    userNameOrId: filters.player
                })
            }
        } else {
            if(filters.betShopId){
                participants.push({
                    ownerType: WALLET_OWNER_TYPE.BETSHOP,
                    userNameOrId: filters.betShopId
                })
            }
        }

        const allTransactionTypes = type === WALLET_OWNER_TYPE.CASHIER ?
            [
                TRANSACTION_TYPE.PLAYER_DEPOSIT,
                TRANSACTION_TYPE.PLAYER_WITHDRAWAL,
                TRANSACTION_TYPE.BET,
                TRANSACTION_TYPE.CANCEL_BET,
                TRANSACTION_TYPE.PAIDOUT,
                TRANSACTION_TYPE.REJECT_BET,
                TRANSACTION_TYPE.RECALCULATE,
                TRANSACTION_TYPE.WON,
                TRANSACTION_TYPE.BETSHOP_CASHIER_HANDOVER_CASHIER,
                TRANSACTION_TYPE.ISSUE_VOUCHER,
                TRANSACTION_TYPE.PAYOUT_VOUCHER
            ] :
            [
                TRANSACTION_TYPE.BETSHOP_MANAGER_DEPOSIT_CASHIER,
                TRANSACTION_TYPE.BETSHOP_MANAGER_WITHDRAW_CASHIER,
                TRANSACTION_TYPE.BETSHOP_LIMIT_AUTO_ADJUSTMENT,
                TRANSACTION_TYPE.BETSHOP_LIMIT_MANUAL_ADJUSTMENT,
            ]


        let params = {
            ...getState().transactions.sorting,
            from: filters.from,
            to: filters.to,
            transactionId: filters.transactionId,
            betShopId: filters.betShopId,
            transactionTypes: filters.transactionTypes ? [filters.transactionTypes] : allTransactionTypes,
            participants: participants && participants.length > 0 ? participants : null
        };

        dispatch(setTransactionsActionBefore());

        const apiURL = type === WALLET_OWNER_TYPE.CASHIER ? ApiUrls.GET_CASHIER_TRANSACTIONS : ApiUrls.GET_BETSHOP_TRANSACTIONS;

        return axios({
            url: apiURL,
            method: Methods.POST,
            data: params,
        })
            .then(({ data: { value: transactions } }) => {
                dispatch(setTransactions(transactions));
                dispatch(setTransactionsActionFinished());
            })
            .catch((ex) => {
                dispatch(setTransactionsActionFinished());
            });
    }
}
