import Methods from "constants/httpMethods.constants";
import ApiUrls from "constants/api.constants";
import axios from "axios";

import {
    SET_TERMINAL_AUTOSUGGESTION,
    SET_CASHIER_AUTOSUGGESTION
} from "store/actionTypes";

const setAvailableTerminals = terminals => ({
    type: SET_TERMINAL_AUTOSUGGESTION,
    payload: { terminals },
});

const setAvailableCashiers = cashiers => ({
    type: SET_CASHIER_AUTOSUGGESTION,
    payload: { cashiers },
});

export const getAvailableTerminals = betShopId => {
    return dispatch => {
        
        return axios({
            url: ApiUrls.GET_TERMINAL_AUTOSUGGESTION,
            method: Methods.GET,
            params: {
                betShopId
            }
        })
            .then(({ data: { value: terminals } }) => {
                dispatch(setAvailableTerminals(terminals));
            })
    }
}

export const getAvailableCashiers = () => {
    return dispatch => {
        
        return axios({
            url: ApiUrls.GET_CASHIER_AUTOSUGGESTION,
            method: Methods.GET,
        })
            .then(({ data: { value: cashiers } }) => {
                dispatch(setAvailableCashiers(cashiers));
            })
    }
}