import React from 'react';

import VoucherHistoryTable from "components/common/voucherHistory/table";
import VoucherHistoryFilters from "components/common/voucherHistory/filters";

const VoucherHistory = () => {
    return (
        <div className="rt--flex rt--flex-col" style={{height: "100%"}}>
            <VoucherHistoryFilters />

            <VoucherHistoryTable />
        </div>
    );
};

export default VoucherHistory;
