import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Table from "components/common/table";
import Pagination from "components/common/pagination";

import { getManagerBetshops, setManagerBetshopsSorting } from 'store/actions/dashboard/managerBetshops.action';

import PATHS from 'constants/path.constants';

import sortingType from 'types/sorting.type';
import betshopType from 'types/betshop.type';

import noBetshopImg from "assets/images/noBetshop.png"

/** Betshop Manager Betshops Page Betshops Table Component */

const Betshops = ({
    getManagerBetshops,
    setManagerBetshopsSorting,
    isLoading,
    betshops,
    total,
    sorting,
    columns
}) => {

    const { t } = useTranslation();

    const navigate = useNavigate();


    return (
        <Fragment>
            <Table
                name="betshops"
                loadFn={getManagerBetshops}
                setSortingFn={setManagerBetshopsSorting}
                columns={columns}
                data={betshops}
                isLoading={isLoading}
                sorting={sorting}
                emptyImg={noBetshopImg}
                emptyText={t("common.noBetshopsFound")}
                actions={
                    [{
                        icon: "icon-right",
                        onClick: record => {
                            navigate({
                                pathname: PATHS.MANAGER_BETSHOP_EDIT + "/" + record.id,
                                search: "name=" + record.name
                            })
                        }
                    }]
                }
            />
            <Pagination
                total={total}
                onChange={value => setManagerBetshopsSorting({
                    ...sorting,
                    ...value
                })}
                page={sorting.page}
                limit={sorting.limit}
                loadFn={getManagerBetshops}
            />
        </Fragment>
    )
}

/** Betshops propTypes
    * PropTypes
*/
Betshops.propTypes = {
    /** Redux action to get betshop manager betshops */
    getManagerBetshops: PropTypes.func,
    /** Redux action to set betshop manager betshops sorting */
    setManagerBetshopsSorting: PropTypes.func,
    /** Redux state property, is true when loading betshop manager betshops */
    isLoading: PropTypes.bool,
    /** Redux state property, betshop manager betshops sorting */
    sorting: sortingType,
    /** Redux state property, the array of betshop manager betshops */
    betshops: PropTypes.arrayOf(betshopType),
    /** Redux state property, bet betshop items count */
    total: PropTypes.number,
    /** Columns data */
    columns: PropTypes.arrayOf(PropTypes.object)
}

const mapDispatchToProps = dispatch => (
    {
        getManagerBetshops: () => {
            dispatch(getManagerBetshops());
        },

        setManagerBetshopsSorting: sorting => {
            dispatch(setManagerBetshopsSorting(sorting));
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.managerBetshops.isLoading,
        sorting: state.managerBetshops.sorting,
        betshops: state.managerBetshops.betshops,
        total: state.managerBetshops.total
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Betshops
);
