import React, {useState} from 'react';
import {Button, Modal} from "antd";
import {getUser} from "utils/auth.js";
import {ENVIRONMENT_TYPE} from "constants/common.constants.js";
import {POPUP_SIZE} from "constants/popup.constants.js";
import {useTranslation} from "react-i18next";
import {TERMINAL_STATE} from "constants/terminal.constants.js";
import {getTerminals} from "store/actions/dashboard/terminals.action.js";
import {connect} from "react-redux";
import {changeTerminalState} from "components/common/terminals/blockModal/api.js";

const BlockModal = ({ 
    terminal, 
    getTerminals,
    betShopId 
}) => {
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const isBlocked = terminal.status === TERMINAL_STATE.BLOCKED;

    const handleOpenModal = () => {
        setOpened(true);
    }

    const handleCloseModal = () => {
        setOpened(false);
    };

    const handleChangeTerminalState = () => {
        setLoading(true);

        changeTerminalState({
            id: terminal.id,
            status: isBlocked ? TERMINAL_STATE.ACTIVE : TERMINAL_STATE.BLOCKED,
        }).then(() => {
            getTerminals(betShopId);
            setOpened(false);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <Button type="ghost" onClick={handleOpenModal}>
                {isBlocked
                    ? <i className="icon-unblock rt--font-bigest"/>
                    : <i className="icon-block rt--font-bigest"/>
                }
            </Button>

            <Modal
                className='rt--modal rt--modal-register-player-modal'
                title={(
                    <div className='rt--flex rt--justify-between rt--align-center'>
                        <span className='rt--title rt--font-normal rt--font-regular'>{isBlocked ? t("common.unblockTerminal") : t('common.blockTerminal')}</span>
                        <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={handleCloseModal} />
                    </div>
                )}
                visible={opened}
                onCancel={handleCloseModal}
                closable={false}
                maskClosable={false}
                getContainer={() => document.getElementById('rt--modal-root')}
                cancelButtonProps={{ className: 'rt--button-secondary' }}
                okButtonProps={{ disabled: loading, loading: loading }}
                cancelText={t('common.cancel')}
                okText={isBlocked ? t('common.unblock') : t('common.block')}
                onOk={handleChangeTerminalState}
                centered
            >
                <span className='rt--title rt--font-normal rt--font-regular'>
                    {isBlocked
                        ? t("common.unblockTerminalMessage").replace("%X%", terminal.name)
                        : t("common.blockTerminalMessage").replace("%X%", terminal.name)
                    }
                </span>
            </Modal>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getTerminals: betShopId => dispatch(getTerminals(betShopId))
})

export default connect(null, mapDispatchToProps)(BlockModal);
