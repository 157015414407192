import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Filters from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import ColumnsButton from "components/common/columnsButton";
import Export from "components/common/export";

import {
    setBetshopPerformanceReportFilters,
    getBetshopPerformanceReport,
    getBetshopPerformanceTotals
} from "store/actions/dashboard/betshopPerformanceReport.action";

import { getManagerAvailableBetshops } from "store/actions/dashboard/managerBetshops.action";
import { getUserAvailableCurrencies } from "store/actions/dashboard/userInfo.action";

import ApiUrls from "constants/api.constants";
import { DATE_PICKER_RANGES } from 'constants/common.constants';
import { TIME_PICKER_MODES } from 'components/common/dateRangePicker/constants';

import managerAvailableBetshopType from 'types/managerAvailableBetshop.type';

/** Betshop Manager Betshop Performance Report Page Filters Component */
const BetshopPerformanceReportFilters = ({
    setBetshopPerformanceReportFilters,
    getBetshopPerformanceReport,
    getBetshopPerformanceTotals,
    filters,
    getManagerAvailableBetshops,
    availableBetshops,
    currencies,
    getUserAvailableCurrencies,
    columns
}) => {

    const { t } = useTranslation();

    /** Flag to hide/show filters */
    const [opened, setOpened] = useState(false);

    const mappedFilters = {
        from: filters.from,
        to: filters.to,
        period: filters.period,
        currencies: filters.currencies,
        filterType: 1
    }

    /** Reset filter userNameOrId field on component unmount */
    useEffect(() => () => setBetshopPerformanceReportFilters({ betShopNameOrId: "" }), [])

    /** Get manager available betshops and cashiers */
    useEffect(() => {
        getManagerAvailableBetshops();
    }, [])

    /** Get manager currencies */
    useEffect(() => {
        getUserAvailableCurrencies();
    }, [])

    /** Function, handler for betshopnameorid search input
         * @function
         * @param {string} value - the field value
         * @memberOf BetshopPerformanceReportFilters
    */
    const handleSearchChange = value => {
        setBetshopPerformanceReportFilters({
            betShopNameOrId: value
        })
        setTimeout(() => {
            getBetshopPerformanceReport();
            getBetshopPerformanceTotals();
        }, 10)
    }

    useEffect(() => {
        return () => {
            setBetshopPerformanceReportFilters({
                betShopNameOrId: ""
            })
        }
    }, [])

    return (
        <div className='rt--filters-header rt--mt-32 rt--pb-16 rt--mb-30'>

            <div className='rt--flex rt--justify-between rt--align-center'>
                <div className='rt--flex rt--align-center rt--filters-header-title'>
                    <span className='rt--title rt--font-big rt--font-regular'>{t("common.betshopPerformanceReport")}</span>
                </div>

                <div className='rt--flex'>

                    <div className='rt--filters-header-search'>
                        <AutoComplete
                            placeholder={t("common.searchByBetshopNameOrId")}
                            items={availableBetshops}
                            onSelect={handleSearchChange}
                        />
                        <i className='icon-search rt--font-bigest'></i>
                    </div>

                    <Export
                        filters={mappedFilters}
                        title={t("common.betshopPerformanceReport")}
                        columns={columns.mainTableColumns}
                        url={ApiUrls.EXPORT_BETSHOP_PERFORMANCE_REPORT}
                        tableName={t("common.betshopPerformanceReport")}
                    />

                    <button
                        className='rt--button rt--button-secondary rt--ml-16'
                        onClick={() => setOpened(!opened)}
                    >
                        <span className='rt--flex rt--justify-center rt--align-center'>
                            <i className='icon-filter rt--font-bigest'></i>
                            <span className='rt--title rt--font-medium rt--font-normal'>{t("common.filters")}</span>
                        </span>
                    </button>

                    <ColumnsButton
                        columns={columns.columnsThatCanBeIncluded}
                        onApply={columns.keepAppliedColumns}
                        defaultSelectedColumns={columns.includedColumns}
                    />
                </div>
            </div>
            <Filters
                loadFn={() => {
                    getBetshopPerformanceReport();
                    getBetshopPerformanceTotals();
                }}
                setFiltersFn={setBetshopPerformanceReportFilters}
                useDateWithTime={true}
                filters={mappedFilters}
                controls={[
                    {
                        title: t('common.timePeriod'),
                        name: "period",
                        type: "SELECT",
                        small: true,
                        items: [
                            { value: DATE_PICKER_RANGES.TODAY, title: t('common.today') },
                            { value: DATE_PICKER_RANGES.YESTERDAY, title: t('common.yesterday') },
                            { value: DATE_PICKER_RANGES.THIS_MONTH, title: t('common.thisMonth') },
                            { value: DATE_PICKER_RANGES.LAST_MONTH, title: t('common.lastMonth') },
                            { value: DATE_PICKER_RANGES.LAST_3_MONTH, title: t('common.3Months') },
                            { value: DATE_PICKER_RANGES.YTD, title: t('common.ytd') },
                            { value: DATE_PICKER_RANGES.LAST_YEAR, title: t('common.lastYear') }
                        ]
                    },
                    {
                        title: t('common.dateRange'),
                        name: "date",
                        type: "RANGEPICKER",
                        mode: TIME_PICKER_MODES.ONLY_HOURS
                    },
                    {
                        title: t('common.currency'),
                        name: "currencies",
                        type: "SELECT",
                        items: [
                            { value: null, title: t("common.all") },
                            ...currencies.map(b => ({ value: b, title: b })),
                        ],
                        small: true
                    }
                ]}
                visible={opened}
            />
        </div>

    )
}

/** BetshopPerformanceReportFilters propTypes
    * PropTypes
*/
BetshopPerformanceReportFilters.propTypes = {
    /** Redux action to get betshop performance report */
    getBetshopPerformanceReport: PropTypes.func,
    /** Redux action to get betshop performance report totals */
    getBetshopPerformanceTotals: PropTypes.func,
    /** Redux action to set betshop performance report filters */
    setBetshopPerformanceReportFilters: PropTypes.func,
    /** Redux state property, betshop performance report filters */
    filters: PropTypes.object,
    /** Redux action to get betshop manager available betshops*/
    getManagerAvailableBetshops: PropTypes.func,
    /** Redux state property, available betshops for betshop manager */
    availableBetshops: PropTypes.arrayOf(managerAvailableBetshopType),
    /** Redux action to get user available currencies */
    getUserAvailableCurrencies: PropTypes.func,
    /** User Available Currencies */
    currencies: PropTypes.arrayOf(PropTypes.string),
    /** Columns data */
    columns: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getBetshopPerformanceReport: () => {
            dispatch(getBetshopPerformanceReport());
        },

        getBetshopPerformanceTotals: () => {
            dispatch(getBetshopPerformanceTotals());
        },

        setBetshopPerformanceReportFilters: filters => {
            dispatch(setBetshopPerformanceReportFilters(filters));
        },

        getManagerAvailableBetshops: () => {
            dispatch(getManagerAvailableBetshops())
        },

        getUserAvailableCurrencies: () => {
            dispatch(getUserAvailableCurrencies())
        },
    }
)

const mapStateToProps = state => {
    return {
        filters: state.betshopPerformanceReport.filters,
        availableBetshops: state.managerBetshops.availableBetshops,
        currencies: state.profile.currencies
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BetshopPerformanceReportFilters);
