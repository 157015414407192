import {
    SET_TERMINALS,
    SET_TERMINALS_ACTION_BEFORE,
    SET_TERMINALS_ACTION_FINISH, SET_TERMINALS_FILTERS,
    SET_TERMINALS_SORTING
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TERMINALS:
            return {
                ...state,
                terminals: payload.terminals.item2,
                total: payload.terminals.item1,
            }
        case SET_TERMINALS_SORTING:
            return {
               ...state,
                sorting: {
                    ...state.sorting,
                    ...payload.sorting
                }
            }
        case SET_TERMINALS_FILTERS:
            return {
               ...state,
                filters: {
                    ...state.filters,
                    ...payload.filters
                }
            }
        case SET_TERMINALS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            }
        case SET_TERMINALS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;
    }
}
