export const POPUP_TYPE = {
    CLOSE: 0,
    CONFIRMATION: 1,
    CHANGE_BETSHOP: 2,
    CHECK_TICKET: 3,
    BET_HISTORY: 4,
    PERFORMANCE_REPORT: 5,
    REGISTER_PLAYER: 6,
    TRANSFER_PLAYER: 7,
    ADD_CASHIER: 8,
    TRANSFER_HISTORY: 9,
    MONEY_TRANSFER: 10,
    SHIFT_HANDOVER: 11,
    GET_PLAYER: 12,
    VOUCHER_CREATE: 13,
    FAILED_TRANSFER: 14,
    TERMINALS: 15,
    VOUCHER_HISTORY: 16
}

export const POPUP_SIZE = {
    SMALLEST: 340,
    SMALL: 400,
    NORMAL: 560,
    BIG: 800,
    BIGEST: 1888
}
