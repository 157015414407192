import Methods from "constants/httpMethods.constants";
import PATHS from "constants/api.constants";
import axios from "axios";
import {
    SET_VOUCHER_HISTORY,
    SET_VOUCHER_HISTORY_ACTION_BEFORE,
    SET_VOUCHER_HISTORY_ACTION_FINISH,
    SET_VOUCHER_HISTORY_FILTERS,
    SET_VOUCHER_HISTORY_SORTING, SET_VOUCHER_HISTORY_TOTALS
} from "store/actionTypes";

export const setVoucherHistory = (vouchers) => ({
    type: SET_VOUCHER_HISTORY,
    payload: { vouchers }
});
export const setVoucherHistoryTotals = (totals) => ({
    type: SET_VOUCHER_HISTORY_TOTALS,
    payload: { totals }
});

export const setVoucherHistoryActionBefore = () => ({
    type: SET_VOUCHER_HISTORY_ACTION_BEFORE,
})

export const setVoucherHistoryActionFinish = () => ({
    type: SET_VOUCHER_HISTORY_ACTION_FINISH,
})

export const setVoucherHistoryFilters = filters => {
    return ({
        type: SET_VOUCHER_HISTORY_FILTERS,
        payload: {filters},
    });
};

export const setVoucherHistorySorting = sorting => ({
    type: SET_VOUCHER_HISTORY_SORTING,
    payload: { sorting },
});

export const getVoucherHistoryTotals = () => {
    return (dispatch, getState) => {
        const filters = getState().voucherHistory.filters;
        const sorting = getState().voucherHistory.sorting;

        const params = {
           ...filters,
           ...sorting
        }

        return axios({
            url: PATHS.GET_VOUCHERS_TOTALS,
            method: Methods.GET,
            params: params,
        })
       .then(response => {
           dispatch(setVoucherHistoryTotals(response.data.value));
        })
       .catch(error => {
            console.error('Error fetching vouchers totals:', error);
            return 0;
        });
    };
}


export const getVoucherHistory = () => {
    return (dispatch, getState) => {
        const filters = getState().voucherHistory.filters;
        const sorting = getState().voucherHistory.sorting;

        const params = {
            ...filters,
            ...sorting
        }

        dispatch(setVoucherHistoryActionBefore());

        return axios({
            url: PATHS.GET_VOUCHERS,
            method: Methods.GET,
            params: params,
        })
       .then(response => {
            dispatch(setVoucherHistory(response.data.value));
        })
       .catch(error => {
            console.error('Error fetching vouchers:', error);
        })
       .finally(() => {
           dispatch(setVoucherHistoryActionFinish());
       });
    };
}
