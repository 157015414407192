import React from 'react';
import CashiersFilters from "./filters";
import CashiersTable from "./table";

const Cashiers = ({
    betShopId
}) => {
    return (
        <div className="rt--flex rt--flex-col" style={{ height: "100%" }}>
            <CashiersFilters betShopId={betShopId} />

            <CashiersTable betShopId={betShopId} />
        </div>
    )
};

export default Cashiers;